.container-presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}
.item-presentation {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item-presentation figure {
  width: 100%;
  margin: 0;
}

.item-presentation figure img {
  max-width: 50vw;
  margin: 0 auto;
  height: 50vw;
  border-radius: 50%;
  border-width: .5px;
  padding: 0.1rem;
  background-color: white;
  animation: fotoGiratoria 5s ease-in-out  infinite alternate;
}
@keyframes fotoGiratoria {
  0% {
    box-shadow: 0px 0px 50px 1px var(rgba(255, 255, 255, 0.157));
    -webkit-box-shadow: 0px 0px 76px 1px var(rgba(255, 255, 255, 0.157));
    -moz-box-shadow: 0px 0px 76px 1px var(rgba(255, 255, 255, 0.157));
    background-color: var(rgba(255, 255, 255, 0.157));
  }
  50% {
    box-shadow: 0px 0px 76px 1px var(rgba(255, 255, 255, 0.502));
    -webkit-box-shadow: 0px 0px 76px 1px var(rgb(255, 255, 255));
    -moz-box-shadow: 0px 0px 76px 1px var(rgba(255, 255, 255, 0.502));
    background-color: var(rgba(255, 255, 255, 0.502));
  }
  100% {
    box-shadow: 0px 0px 76px 1px var(white);
    -webkit-box-shadow: 0px 0px 76px 1px var(white);
    -moz-box-shadow: 0px 0px 76px 1px var(white);
    background-color: var(white);
  }
}
.item-presentation {
  text-align: center;
}
.item-presentation h1 {
  color: var(--white);
  margin-bottom: .2rem;
  font-size: 7vw;
  font-family: "Roboto-Bold";
}
.item-presentation h3 {
  color: var(--grey-primary);
  font-size: 5vw;
  font-family: "Roboto-Medium";
}
@media (min-width: 576px) { 
  .item-presentation figure img {
    max-width: 30vw;
    height: 30vw;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .container-presentation {
    flex-direction: row;
    width: 100%;
    height: 100vh;
  }
  .item-presentation figure img {
    max-width: 25vw;
    height: auto;
  }
  .item-presentation {
    width: 50%;
  }
  /* textos */
  .item-presentation h1 {
    color: var(--white);
    font-size: 3.2vw;
    font-family: "Roboto-Bold";
    margin-bottom: .3rem;
  }
  .item-presentation h3 {
    color: var(--grey-primary);
    font-size: 2vw;
    font-family: "Roboto-Medium";
  }
  /* fin textos */
 }



