h2 {
  color: var(--white);
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0 2rem 0;
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  h2 {
    margin: 0 0 3rem 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  h2 {
    margin: 0 0 4rem 0;
  }
}