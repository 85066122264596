.container-footer {
  position: fixed;
  background: var(--grey-primary);
  margin-bottom: 1.5vh;
  width: 95vw;  
  bottom: -50%;
  left: 50%;
  transform: translate(-50%,0);
  border-radius: 21px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: .6rem 0;
  opacity: 0;
  transition: bottom .5s ease-in-out, opacity 2s ease-in-out;
  /* backdrop-filter: blur(15px); */
}

.item-footer a {
  display: flex;
  flex-direction: column;
  text-align: inherit;
  color: var(--purple-primary);
  text-decoration: none;
}

.item-footer a small {
  display: block;
  text-align: inherit;
}
.show-footer {
  bottom: 0;
  opacity: 1;
}
@media (min-width: 768px) { 
  .container-footer {
    display: none;
  }
}