.container-general-comments {
  height: 80vh;
  overflow-y: hidden;
  padding: .3rem 0;
}
.container-general-comments::-webkit-scrollbar {
  display: none;
}
.container-comments {
  position: relative;
  display:  grid;
  grid-template-columns: repeat(1, 1fr);
  height: 70%;
  overflow-y: auto;
  gap:.7rem;
  padding: .2rem;
}
.btn-comments {
  position: sticky;
  width: 45%;
  bottom: 0;
  left: 90%;
  background: rgba(255, 255, 255, 0.19);
  color: var(--white);
  border-radius: 10px;
  appearance: initial;
  border: none;
  padding: 0.2rem 0.5rem;
  font-family: "Roboto-Bold";
  font-size: 1rem;
  letter-spacing: 1px;
  margin-top: 1rem;
  cursor: pointer;
}
.btn-comments svg {
  padding-right: .5rem;
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-comments {
    grid-template-columns: repeat(2, 1fr);
    gap:.5rem;
  }
  .container-general-comments {
    height: 40vh;
  }
  .btn-comments {
    width: 125px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .container-comments {
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-template-columns: none;
    grid-auto-columns: 400px;
    gap:.5rem;
    overflow: hidden;
  }
  .container-general-comments {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .item-comments {
    min-width: 30%;
  }
  .btn-comments {
    width: 125px;
  }
}