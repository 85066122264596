.container-portfolio {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1,1fr);
}
.container-general-portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-portfolio {
    grid-template-columns: repeat(2,1fr);
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .container-portfolio {
    grid-template-columns: repeat(4,1fr);
  }
}

