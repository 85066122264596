.contenedor-dot {
  position: relative;
  border-left: 1px  solid rgba(255, 255, 255, 0.400) ;
  padding-left: 1rem;
}
.contenedor-dot svg {
  position: absolute;
  top: 0;
  left: -.6rem;
} 
.item-about h5 {
  color: var(--white);
  font-size: .9rem;
  margin: .1rem 0;
}
.item-about h6 {
  font-size: .8rem;
  margin: .4rem 0;
}
.item-about ul {
  padding-inline-start: 1rem;
  margin: 0;
  padding-bottom: .3rem;
}
