.container-modal {
  width: 95vw;
  height: 97vh;
  border-radius: 21px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;
  transition: all 1s ease-in-out;
}
.container-modal .modal-content {
  width: 100%;
  height: auto;
  background: rgba(196, 196, 196, 0.045);
  position: relative;
  z-index: 999;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 10px rgb(255 255 255 / 40%);
  -webkit-box-shadow: 0px 0px 2px 0px rgb(255 255 255 / 40%);
  padding: 0.5rem 1rem;
  padding-bottom: 1rem;
}
.show-modal {
  opacity: 1;
  z-index: 99;
}
.modal-header {
  display: flex;
  justify-content: end;
}
.modal-header svg {
  margin: .5rem;
  margin-bottom: .7rem;
  cursor: pointer;
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-modal .modal-content {
    width: 25%;
  }
  .container-modal .modal-content.modal-lg {
    width: 70%;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 992px) { 
  .container-modal .modal-content.modal-lg {
    width: 70%;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 1200px) { 
  .container-modal .modal-content.modal-lg {
    width: 60%;
  }
}