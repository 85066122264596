button.btn {
  width: auto;
  /* background: linear-gradient(90deg, rgba(204,68,204,1) 1%, rgba(184,44,203,1) 14%, rgba(1,95,251,1) 57%, rgba(21,18,228,1) 100%); */
  background: rgba(255, 255, 255, 0.19);
  color: var(--white);
  border-radius: 10px;
  appearance: initial;
  border: none;
  padding: 0.2rem 0.5rem;
  font-family: "Roboto-Bold";
  font-size: 1rem;
  letter-spacing: 1px;
  cursor: pointer;
}
.btn-lg {
  height: 3rem;
}
.btn-sm {
  height: 2rem;
}