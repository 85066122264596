.container-skills {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-auto-rows: 100px;
  justify-content: center;
  align-content: center;
  gap: .3rem .5rem;
  justify-items: center;
}
.container-general-skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-skills {
    grid-template-columns: repeat(4,1fr);
    gap: .3rem .5rem;
  }
}

