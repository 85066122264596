.item-skills figure {
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-end;

}

.item-skills figure h4 {
  margin: .5rem;
  color: var(--grey-primary);
  white-space: nowrap;
}
.item-skills figure img{
  width: 3.8em;
  height: 64px;
  /* border: 1px solid white; */
}