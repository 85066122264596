/* 
-----------------------------------
Configuracion inicial
-----------------------------------

*/
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Roboto light";
}
::after,
::before {
  box-sizing: inherit;
}
body {
  margin: 0;
}

:root {
  --pink-primary: #b82ccb;
  --pink-secondary: #cc44cc;
  --purple-primary: #1d1851;
  --purple-secondary: #49346e;
  --blue-primary: #1512e4;
  --blue-secondary: #015ffb;
  --grey-primary: #98accb;
  --white: #ffff;
}
@font-face {
  font-family: "Roboto Regular";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto light";
  src: url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Medium";
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto bold";
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.fondo {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: var(--purple-primary);
  top: 0;
}
.container-general {
  width: 95vw;
  height: 97vh;
  max-width: 1320px;
  background: rgba(196, 196, 196, 0.10);
  /* backdrop-filter: blur(15px); */
  border-radius: 21px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  scroll-behavior: smooth;
}
.container-general::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin-top: 20px;
}
.container-general::-webkit-scrollbar-thumb {
  background-color: var(--grey-primary);
  border-radius: 10px;
}
.container-general:-webkit-scrollbar-track {
  background: red;
  border-radius: 4px;
}

/* 
-----------------------------------
Configuracion inicial
-----------------------------------
*/
.main-general {
  position: relative;
  padding: 1rem;
  padding-left: 1rem;
  height: auto;
  transition: all 1s ease-in-out;
}
.hidden {
  opacity: 0;
}
div[data-tilt="true"] {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  line-height: 0 !important;
}
h1, h2, h3, h4, h5 {
  font-family: 'Roboto-Bold';
}
p, small, li, ul, b, span,label,input {
  font-family: 'Roboto-Regular';
}
section {
  min-height: 97vh;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .main-general {
    padding-left: 90px;
  }
}
